import "./App.css";

function App() {
  return (
    <div className="container">
      <img src="/squatch-head-poggers.png" alt="" className="squatch-head" />
      <a href="https://www.twitch.tv/schadsquatch" className="twitch">
        Click Here for more info!
      </a>
      <h1 className="coming-soon">Coming Soon....</h1>
    </div>
  );
}

export default App;
